import { default as index62xqOHTqwhMeta } from "/tmp/build_a007a90c/pages/connections/index.vue?macro=true";
import { default as indexvWqUzGLBwjMeta } from "/tmp/build_a007a90c/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_93Nb4mTLHQ1BMeta } from "/tmp/build_a007a90c/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93qyxH9KUKMBMeta } from "/tmp/build_a007a90c/pages/gallery/[file_id].vue?macro=true";
import { default as gallerySLC59rJSUMMeta } from "/tmp/build_a007a90c/pages/gallery.vue?macro=true";
import { default as indexJFaSPOzJnlMeta } from "/tmp/build_a007a90c/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_93hQaaCGaRRtMeta } from "/tmp/build_a007a90c/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45in1Ly7sDWQMcMeta } from "/tmp/build_a007a90c/pages/log-in.vue?macro=true";
import { default as log_45outeCWVJ9lnAsMeta } from "/tmp/build_a007a90c/pages/log-out.vue?macro=true";
import { default as indexiDUfcklF2wMeta } from "/tmp/build_a007a90c/pages/notifications/index.vue?macro=true";
import { default as projectsp40c9yQulmMeta } from "/tmp/build_a007a90c/pages/projects.vue?macro=true";
import { default as _91file_id_93LzpCFYBpa4Meta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_932CsTN2QWC9Meta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexwt8XMtI9ENMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedf8Z583gacRMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93mfpBLZcQOFMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93g79F7a1G9kMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93FCmD5UK2L5Meta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as index2bBOb5LT40Meta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93JzFy40vP41Meta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_938IfpZUMbyFMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexbWfjlchS4JMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93pzWFqnIs8yMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectsfO5eL9xZoOMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93OlcNTPkrUMMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93SsF1WWJ77sMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as index8ODLxcT10jMeta } from "/tmp/build_a007a90c/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomeRtXY5VJUuSMeta } from "/tmp/build_a007a90c/pages/welcome.vue?macro=true";
import { default as component_45stubQH3U1iMzZ4Meta } from "/tmp/build_a007a90c/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQH3U1iMzZ4 } from "/tmp/build_a007a90c/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: index62xqOHTqwhMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/connections/index.vue").then(m => m.default || m)
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexvWqUzGLBwjMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/delete-bin/index.vue").then(m => m.default || m)
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_a007a90c/pages/external/embed/[user_id]/[file_id]/[share_token].vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: gallerySLC59rJSUMMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/gallery.vue").then(m => m.default || m),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_a007a90c/pages/gallery/[file_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexJFaSPOzJnlMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_a007a90c/pages/impersonate/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_a007a90c/pages/log-in.vue").then(m => m.default || m)
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_a007a90c/pages/log-out.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexiDUfcklF2wMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    meta: projectsp40c9yQulmMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/projects.vue").then(m => m.default || m)
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue").then(m => m.default || m),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexwt8XMtI9ENMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/index.vue").then(m => m.default || m)
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedf8Z583gacRMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/albums/visited.vue").then(m => m.default || m)
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue").then(m => m.default || m),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue").then(m => m.default || m)
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue").then(m => m.default || m),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexbWfjlchS4JMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/people/index.vue").then(m => m.default || m)
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectsfO5eL9xZoOMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/print-projects.vue").then(m => m.default || m),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/print-projects/[file_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue").then(m => m.default || m),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: index8ODLxcT10jMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/users/[user_id_or_slug]/tags/index.vue").then(m => m.default || m)
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeRtXY5VJUuSMeta || {},
    component: () => import("/tmp/build_a007a90c/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: component_45stubQH3U1iMzZ4Meta?.name,
    path: "/",
    component: component_45stubQH3U1iMzZ4
  },
  {
    name: component_45stubQH3U1iMzZ4Meta?.name,
    path: "/library",
    component: component_45stubQH3U1iMzZ4
  },
  {
    name: component_45stubQH3U1iMzZ4Meta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubQH3U1iMzZ4
  }
]