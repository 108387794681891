/**
 * @returns {String} the current domain string
 */
export function getDomain() {
  if (typeof document !== 'undefined') {
    const hostname = document.location.hostname;
    if (hostname === 'localhost') {
      return 'localhost';
    }
    // remove any subdomain
    return hostname.replace(/^([a-z0-9]+(-[a-z0-9]+)*\.)?/, '');
  }
  return '';
}

/**
 * @description
 * Convert a provided map of key value pairs to query string
 * @param {object} objQuery A map of query string parameters to their values
 * @returns {String} The composed query string
 */
export function buildQueryString(objQuery) {
  let count = 0;
  return objQuery && Object.keys(objQuery)
    .sort()
    .reduce((query, key) => {
      const delim = !count ? '?' : '&';
      let queryValue = null;
      let queryKey = `${key}=`;

      if (Array.isArray(objQuery[key])) {
        queryValue = objQuery[key].reduce((str, arrValue) => `${str}&${key}[]=${encodeURIComponent(arrValue)}`, '').slice(1);
        queryKey = '';
      } else if (typeof objQuery[key] !== 'undefined' && objQuery[key] !== null) {
        queryValue = encodeURIComponent(objQuery[key]);
      }

      if (queryValue) {
        count++;
        return `${query}${delim}${queryKey}${queryValue}`;
      }
      return query;
    }, '');
}

/**
 *
 * @param route
 * @param params
 * @returns {string}
 */
export function buildRouteParams(route, params) {
  return route.split('/')
    .map(part => {
      if (part.includes('http') || part.includes('localhost')) {
        return part;
      }
      return part.match(/:/) ? params[part.replace(':', '')] : part;
    })
    .join('/')
    .replace(/\/$/, '');
}

/**
 * @description
 * Convert a provided url or query string into an object
 * @param {String} str
 * @returns {Object}
 */
export function parseQueryString(str) {
  const urlParts = str.split('?');
  const queryStr = urlParts[urlParts.length - 1];
  const queryStrParts = queryStr.split('&');

  return queryStrParts.reduce((obj, part) => {
    const [key, value] = part.split('=');
    obj[key] = value;
    return obj;
  }, {});
}


/**
 * Given a URL, manage querystrings
 * @param uri
 * @param updates {object} object representation of names and values
 *
 * @returns {string}
 *
 * example:
 *
 */
export function updateQueryParameters(uri, updates) {
  const url = new URL(uri);

  for (const key in updates) {
    if (Object.prototype.hasOwnProperty.call(updates, key)) {
      const value = updates[key];
      if (value === null) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value);
      }
    }
  }

  return url.toString();
}
