export default defineAppConfig({
  ui: {
    primary: 'heroblue',
    gray: 'stone',

    tooltip: {
      popper: {
        arrow: true
      },
      base: 'text-sm h-7'
    },
    dropdown: {
      popper: {
        arrow: true
      }
    },

    avatar: {
      placeholder: 'text-white',
      size: {
        '4xl': 'h-28 w-28 text-4xl',
        '5xl': 'h-36 w-36 text-4xl'
      }
    },

    input: {
      rounded: 'rounded-none',
      default: {
        size: 'md'
      }
    },

    textarea: {
      rounded: 'rounded-none',
      default: {
        size: 'md'
      }
    },

    checkbox: {
      label: 'font-semibold'
    },

    formGroup: {
      label: {
        base: 'font-semibold text-base'
      }
    },

    button: {
      font: 'font-semibold',
      rounded: 'rounded',
      variant: {
        link: 'no-underline'
      }
    },

    notification: {
      title: 'font-semibold text-base'
    },

    configMenu: {
      default: {
        showCreateOptionWhen: 'always'
      }
    },

    alert: {
      padding: 'p-2.5',
      rounded: 'rounded',
      title: 'font-semibold',
      default: {
        variant: 'soft'
      }
    },

    selectMenu: {
      rounded: 'rounded-none',
      default: {
        selectedIcon: 'i-ri-check-line'
      },
      option: {
        active: 'bg-ice-200',
        selectedIcon: {
          base: 'text-sage-800'
        }
      },
      popper: {
        arrow: true
      }
    },

    select: {
      rounded: 'rounded-none'
    },

    badge: {
      default: {
        variant: 'subtle'
      }
    },

    popover: {
      overlay: {
        background: 'bg-black/50'
      }
    }
  }
});
